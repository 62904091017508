import * as React from "react";
import { Link } from "gatsby";
import Seo from "../components/seo";
import Layout from "../components/layout";
import Foot from "../components/foot";
import { Box, Button, Heading, Paragraph, Text } from "grommet";

// markup
const NotFoundPage = () => {
  return (
    <Layout>
      <Seo title="Join twb" />
      <Box
        justify="center"
        gap="medium"
        width="large"
        alignSelf="center"
        margin={{ vertical: "large" }}
      >
        <Box background="dark-1" round="xsmall" pad={{horizontal:"medium", vertical: "large"}} flex={false}>
          <Heading margin={{top: "none"}} level={1} size="small">
            Page not found
          </Heading>
          <Paragraph margin={{ top: "none", bottom: "medium" }}>
            Sorry{" "}
            <Text role="img" aria-label="Pensive emoji">
              😔
            </Text>{" "}
            we couldn’t find what you were looking for.
          </Paragraph>
          <Text>
            <Link to="/">
              <Button
                primary
                value="submit"
                color="accent-2"
                size="medium"
                label="Go to the homepage"
              />
            </Link>
          </Text>
        </Box>
      </Box>
      <Foot />
    </Layout>
  );
};

export default NotFoundPage;
